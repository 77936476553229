import React from "react";

import "./webring.css";

export default function Webring(props)
{
    let [links, setLinks] = React.useState(null);
    let [err, setErr] = React.useState(null);
    React.useEffect(() => {
        setLinks(null);
        setErr(null);
        fetch("https://aidang.cc/webring/https://polymino.net")
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(
                setLinks,
                reason => {console.log(reason); setErr(reason);}
            );
    }, [props]);

    const contents = [];
    if (links === null)
    {
        if (err === null)
        {
            contents.push(null, "Loading...", null);
        }
        else
        {
            contents.push(null, "Webring Error", null);
        }
    }
    else
    {
        contents.push(
            <a href={links.left.url}>« {links.left.name}</a>,
            '"Official" CSSA Web Ring',
            <a href={links.right.url}>{links.right.name} »</a>
        );
    }

    return <div id="webringFooter">
        {contents.map((e, i) => <div key={i}>{e}</div>)}
    </div>;
}
