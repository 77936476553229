import React from "react";

import "./footer.css";

export default function Footer()
{
    return <div id="siteFooter">
        <div>
            © Aidan Garvey, 2023-{new Date().getFullYear()}.
        </div>
        <div>
            Polymino and the Polymino logo are trademarks of Aidan Garvey.
        </div>
        <div>
            All rights reserved.
        </div>
    </div>;
}
