import React from "react";

const divStyle = {
    border: "1px solid white",
    padding: "10px",
    width: "fit-content"
};

const headerStyle = {
    marginBlockStart: "0px",
    marginBlockEnd: "0.25em"
};

/**
 * @typedef {Object} HeaderInfo
 * @prop {number} level
 * @prop {string} name
 * @prop {string} id
 * @prop {HeaderInfo[]} children
 */

/**
 * @param {Element} headerNode 
 * @param {string} headerTag
 * @returns {HeaderInfo}
 */
function headerInfo(headerNode, headerTag)
{
    return {
        level: parseInt(headerTag[1]),
        name: headerNode.textContent.trim(),
        id: "#" + headerNode.id,
        children: []
    };
}

/**
 * @param {HeaderInfo[]} headers
 * @returns {HeaderInfo[]}
 */
function foldHeaders(headers)
{
    if (headers.length === 0)
    {
        return [];
    }

    const result = [headers.shift()];
    const currStack = [result[0]];

    while (headers.length > 0)
    {
        const curr = headers.shift();
        // curr header is lower than stack top? nest within
        if (currStack.at(-1).level < curr.level)
        {
            currStack.at(-1).children.push(curr);
            currStack.push(curr);
        }
        // otherwise, find a higher-up parent
        else
        {
            while (currStack.length > 0
                && currStack.at(-1).level >= curr.level)
            {
                currStack.pop();
            }
            if (currStack.length === 0)
            {
                result.push(curr);
            }
            else
            {
                currStack.at(-1).children.push(curr);
            }
            currStack.push(curr);
        }
    }
    return result;
}

/**
 * @param {HeaderInfo[]} headers
 */
function makeHeaderList(headers)
{
    const result = document.createElement("ul");
    for (const h of headers)
    {
        const li = document.createElement("li");
        // add a link to the section
        const a = document.createElement("a");
        a.setAttribute("href", h.id);
        a.innerText = h.name;
        li.appendChild(a);
        // add a nested list after the link if needed
        if (h.children.length > 0)
        {
            li.appendChild(makeHeaderList(h.children));
        }
        result.appendChild(li);
    }
    return result;
}

/**
 * Find all headers and subheaders in the page, and create a table of contents.
 * @param {Object} props
 * @param {boolean} [props.startCollapsed]
 */
export default function PageContents({startCollapsed})
{
    if (typeof(startCollapsed) === "undefined")
    {
        startCollapsed = false;
    }
    const [collapse, setCollapse] = React.useState(startCollapsed);

    const containerRef = React.useRef(null);

    React.useEffect(() => {
        if (!containerRef.current) return;

        const headerList = [];
        /**
         * @param {HTMLElement} node
         */
        const dfs = (node) => {
            if (!node) return;

            for (const child of node.children)
            {
                const headerTag = child.tagName.match(/^h[1-3]/i);
                if (headerTag !== null)
                {
                    headerList.push(headerInfo(child, headerTag[0]));
                }
                else
                {
                    dfs(child);
                }
            }
        };
        dfs(document.querySelector(".wikiPageBody"));
        /**
         * @type {HTMLDivElement}
         */
        const div = containerRef.current;
        if (headerList.length > 0)
        {
            div.replaceChildren(makeHeaderList(foldHeaders(headerList)));
        }
        else
        {
            const nothingMsg = document.createElement("div");
            nothingMsg.innerText = "Nothing!";
            nothingMsg.setAttribute("style",
                "width:fit-content;margin-left:auto;margin-right:auto;");
            div.replaceChildren(nothingMsg);
        }
    });

    return <div style={divStyle}>
        <h2 style={headerStyle}>Page Contents</h2>
        <span
            className="show-toggle"
            onClick={() => setCollapse(!collapse)}
        >
            {collapse ? "(show)" : "(collapse)"}
        </span>
        {!collapse &&
            <div className="pageContents" ref={containerRef}/>
        }
    </div>;
}
